import React from 'react';
import { faEdit, faEnvelope, faExchange, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import {
	CatalogContacts,
	CatalogGlobalType,
	GetCatalogContacts,
	GetCatalogContactsVariables,
	GetCatalogContacts_catalogContacts,
	GetWebCatalogCustomers_catalogCustomers,
	GetCatalog_catalog_contacts,
	Permissions,
} from '../../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { getCurrentOffset, Modify } from '../../../helper';
import { useQuery } from '@apollo/client';
import { GraphQLExtensionsData } from '@ssg/common/Components/GraphQLExtensionsContext';
import Table from '@ssg/common/Components/Table';
import UserContext from '../../../UserContext';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import CatalogContactModal from './ContactModal';
import ContactsFilter from './ContactsFilter';
import ContactChangeLog from './ContactChangeLog';
import _ from 'lodash';
import ContactAddExisting from './ContactAddExisting';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import Box from '../../../Components/Layout/Box';
import TextButton from '@ssg/common/Components/TextButton';
import DeleteContactModal from './DeleteContactModal';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import GraphQLExtensionsElement from '@ssg/common/Components/GraphQLExtensionsElement';
import TotalCount from '@ssg/common/Components/TotalCount';

const GET_CATALOG_CONTACTS = loader('src/GraphQL/CatalogContacts/GetCatalogContacts.gql');

const FETCH_LIMIT = 20;

interface Props {
	changedBy: string;
	catalogId?: string;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
	contactsData?: GetCatalogContacts_catalogContacts[];
}

export type Contact = Modify<
	GetCatalogContacts_catalogContacts,
	{
		catalogString: string[];
		globalAsString: string;
		activeAsString: string;
		globalForString: string;
		informationsAsString: (string | null)[];
	}
>;

const Contacts: React.FC<Props> = ({ changedBy, catalogId, catalogCustomer, catalogCustomers, contactsData }) => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_EDIT]);
	const isExternalUser = userContext.user?.external ?? true;

	const [showModal, setShowModal] = React.useState(false);
	const [updateContact, setUpdateContact] = React.useState(false);
	const [createContact, setCreateContact] = React.useState(false);
	const [deleteContact, setDeleteContact] = React.useState(false);
	const [addExistingContact, setAddExistingContact] = React.useState(false);
	const [viewContactChanges, setViewContactChanges] = React.useState(false);

	const [contact, setContact] = React.useState<GetCatalog_catalog_contacts>();
	const [contactId, setContactId] = React.useState<string | undefined>(undefined);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);
	const [customerIds, setCustomerIds] = React.useState<null | string[]>(null);
	const [contactType, setContactType] = React.useState<CatalogContacts | undefined>(undefined);

	const {
		loading: loadingContactsList,
		data,
		fetchMore,
	} = useQuery<GetCatalogContacts, GetCatalogContactsVariables>(GET_CATALOG_CONTACTS, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		variables: {
			thisCustomerOnly: isExternalUser,
			contactType: contactType,
			offset: 0,
			limit: FETCH_LIMIT,
			catalogId: catalogId,
			customerIds: customerIds,
			searchString: searchTerm || null,
		},
		skip: typeof contactsData !== 'undefined',
	});

	const contactsList = React.useMemo(() => contactsData ?? data?.catalogContacts ?? [], [data?.catalogContacts, contactsData]);

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const [offset, setOffset] = React.useState(getCurrentOffset((data?.catalogContacts ?? []).length, FETCH_LIMIT));

	const catalogContacsTotalCountName = 'catalogContactsTotalCount';
	const graphQLExtensionsData = React.useContext(GraphQLExtensionsData);
	const totalCatalogContacts = React.useMemo(() => (graphQLExtensionsData[catalogContacsTotalCountName] as number | undefined | null) ?? 0, [graphQLExtensionsData]);

	return (
		<Box full title={catalogId ? 'catalog.contacts' : undefined} className={catalogId && 'mb-8'}>
			<div className="text my-2 flex justify-between">
				<ContactsFilter
					setFilterTerm={setSearchTerm}
					setContactType={setContactType}
					contactType={contactType}
					customerIds={customerIds}
					setCustomerIds={setCustomerIds}
					catalogCustomers={catalogCustomers}
					catalogCustomer={catalogCustomer}
				/>
				{canEdit && (
					<div className="space-y-2">
						<TextButton text="catalog.contact.addContact" icon={faPlus} onClick={() => setCreateContact(true)} />
						{catalogId && <TextButton text="catalog.contact.addExistingContact" icon={faPlus} onClick={() => setAddExistingContact(true)} />}
					</div>
				)}
			</div>

			<GraphQLExtensionsElement
				name="catalogContactsTotalCount"
				render={value => (
					<TotalCount totalCount={value as number | undefined | null} loading={loadingContactsList} quantityText={t('common.quantity')} entityText={t('catalog.contact.overviewTitle')} />
				)}
			/>

			<Table
				data={contactsList}
				loading={loadingContactsList}
				columns={[
					{
						label: 'catalog.customer',
						hideColumn: catalogId !== undefined,
						selectFn: c => <p>{c.customer.name}</p>,
						sortFn: (a, b) => a.customer.name.localeCompare(b.customer.name),
					},
					{
						label: 'common.description',
						classNameTh: 'w-20',
						selectFn: c => <p>{c.type ?? ''}</p>,
						sortFn: (a, b) => (a.type ?? '').localeCompare(b.type ?? ''),
					},
					{
						label: 'catalog.contact.contactName',
						noTruncate: true,
						selectFn: c => <p>{c.contactName}</p>,
						sortFn: (a, b) => a.contactName.localeCompare(b.contactName),
					},
					{
						label: 'catalog.contact.email',
						selectFn: c => (
							<p>
								<a href={`mailto:${c.email}`} className="hover:underline">
									{c.email}
								</a>
							</p>
						),
						sortFn: (a, b) => (a?.email ?? '').localeCompare(b?.email ?? ''),
					},
					{
						label: 'common.phone',
						noTruncate: true,
						selectFn: c => (
							<div>
								{c.informations.map((inf, index) => (
									<div className="py-1" key={index}>
										<p>
											<a href={`tel:${inf?.phoneNumber}`} className="hover:underline">
												{`${inf?.phoneNumber} - ${inf?.remark}`}
											</a>
										</p>
									</div>
								))}
							</div>
						),
					},
					{
						label: 'common.type',
						selectFn: c => <p>{t('catalog.contact.' + c.contactType)}</p>,
						sortFn: (a, b) => a.contactType.localeCompare(b.contactType),
					},
					{
						label: 'catalog.attached',
						selectFn: c => (
							<p>{c.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? t('catalog.customer') : c.globalType === CatalogGlobalType.CATALOGS_GLOBAL ? t('catalog.overviewTitle') : ''}</p>
						),
						sortFn: (a, b) =>
							(a.globalType === CatalogGlobalType.CUSTOMER_GLOBAL
								? t('catalog.customer')
								: a.globalType === CatalogGlobalType.CATALOGS_GLOBAL
								  ? t('catalog.overviewTitle')
								  : ''
							).localeCompare(
								b.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? t('catalog.customer') : b.globalType === CatalogGlobalType.CATALOGS_GLOBAL ? t('catalog.overviewTitle') : '',
							),
					},
					{
						label: 'catalog.timedMessage.catalogs',
						noTruncate: true,
						hideColumn: catalogId !== undefined,
						selectFn: c => {
							const catalogString =
								c.catalogs?.map((cat, catIndex) => {
									let catString = `${t('catalog.propertyNumber')}: ${cat.propertyNumber}`;
									if (!cat.addresses) {
										return catString;
									}
									catString += ` - ${t('catalog.addresses')}: `;
									catString += cat.addresses.map(
										(add, index) =>
											`${add.addressLine}, ${add.zipCode}, ${add.city} ${add.startNumber}-${add.endNumber}${cat.addresses && index !== cat.addresses.length - 1 ? ', ' : ''}`,
									);

									if (c.catalogs && c.catalogs.length - 1 !== catIndex) {
										catString += ', ';
									}

									return catString;
								}) ?? '';

							return <p>{catalogString}</p>;
						},
					},
					{
						label: 'common.active',
						classNameTh: 'text-right',
						hideColumn: catalogId !== undefined,
						selectFn: c => <div className="flex content-start justify-end pr-2">{c.active ? t('common.yes') : t('common.no')}</div>,
						sortFn: (a, b) => (a.active ? 'yes' : 'no').localeCompare(b.active ? 'yes' : 'no'),
					},
					{
						label: 'common.changes',
						classNameTh: 'text-right',
						selectFn: c => {
							if (c.version > 1) {
								return (
									<div className="flex content-start justify-end pr-2">
										<FontAwesomeIcon
											icon={faExchange}
											size="lg"
											onClick={() => {
												setContactId(c.id);
												setViewContactChanges(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								);
							}
							return <></>;
						},
					},
					{
						label: 'common.edit',
						hideColumn: !canEdit || isExternalUser,
						classNameTh: 'text-right',
						selectFn: c => (
							<div className="flex content-start justify-end text-right">
								<FontAwesomeIcon
									icon={faEdit}
									size="lg"
									onClick={() => {
										setContact(c);

										if (c.global) {
											setShowModal(true);
										} else {
											setUpdateContact(true);
										}
									}}
									className="cursor-pointer"
								/>
							</div>
						),
					},
					{
						label: 'common.remove',
						classNameTh: 'text-right',
						hideColumn: !canEdit,
						selectFn: c => (
							<div>
								<div className="text-red flex content-start justify-end text-right">
									{(!c.global || !catalogId) && (
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setContact(c);
												setDeleteContact(true);
											}}
											className="cursor-pointer"
										/>
									)}
								</div>
							</div>
						),
					},
				]}
				keySelector={c => c.id}
				noDataFoundText={'catalog.contact.noContactsFound'}
			/>
			{typeof data?.catalogContacts !== 'undefined' && data?.catalogContacts !== null && totalCatalogContacts > data?.catalogContacts.length && (
				<Button
					fullWidth
					text="common.getMore"
					secondary
					loading={fetchMoreLoading}
					className="mt-2"
					onClick={async () => {
						setFetchMoreLoading(true);
						setOffset(contactsList.length);
						await fetchMore({
							variables: {
								offset: contactsList.length,
							},
						});
						setFetchMoreLoading(false);
					}}
				/>
			)}
			{typeof contactsData !== 'undefined' && (
				<div className="mt-2 ml-2">
					<a
						className="text-sm"
						href={`mailto:${_.toString(
							contactsList.map(ct => {
								if (ct.email) return `${ct?.email};`;
								return '';
							}),
						).replaceAll(',', '')}`}
					>
						<FontAwesomeIcon icon={faEnvelope} /> {t('common.mailAll')}
					</a>
				</div>
			)}

			{/* Contact Log */}
			{contactId && (
				<ContactChangeLog
					contactId={contactId}
					open={viewContactChanges}
					close={() => {
						setViewContactChanges(false);
						setContactId(undefined);
					}}
				/>
			)}

			{addExistingContact && typeof catalogCustomer !== 'undefined' && catalogId && (
				<ContactAddExisting
					changedBy={changedBy}
					open={addExistingContact}
					catalogCustomer={catalogCustomer}
					catalogContactsIds={contactsData?.map(c => c.id) ?? []}
					catalogId={catalogId}
					close={() => setAddExistingContact(false)}
				/>
			)}

			{/* Catalog Contact Modal */}
			<div>
				{createContact && (
					<CatalogContactModal
						changedBy={changedBy}
						catalogId={catalogId}
						catalogCustomer={catalogCustomer}
						catalogCustomers={catalogCustomers}
						open={createContact}
						close={() => setCreateContact(false)}
						fetchVariables={{
							thisCustomerOnly: isExternalUser,
							contactType: contactType,
							offset: offset,
							limit: FETCH_LIMIT,
							catalogId: catalogId,
							customerIds: customerIds,
							searchString: searchTerm || null,
						}}
					/>
				)}

				{updateContact && contact && (
					<CatalogContactModal
						changedBy={changedBy}
						catalogId={catalogId}
						catalogCustomer={catalogCustomer}
						catalogCustomers={catalogCustomers}
						open={updateContact}
						close={() => {
							setShowModal(false);
							setUpdateContact(false);
						}}
						edit
						data={contact}
						fetchVariables={{
							thisCustomerOnly: isExternalUser,
							contactType: contactType,
							offset: offset,
							limit: FETCH_LIMIT,
							catalogId: catalogId,
							customerIds: customerIds,
							searchString: searchTerm || null,
						}}
					/>
				)}

				{deleteContact && contact && (
					<DeleteContactModal
						changedBy={changedBy}
						catalogId={catalogId}
						catalogCustomer={catalogCustomer}
						visible={deleteContact}
						close={() => setDeleteContact(false)}
						contactData={contact}
						isExternalUser={isExternalUser}
					/>
				)}
			</div>

			<Modal
				title={t('common.editGlobal')}
				size={ModalSize.SMALL}
				visible={showModal}
				close={() => setShowModal(false)}
				body={
					<div>
						<strong>{t('catalog.contact.editGlobalContact')}</strong>
						<p>{t('catalog.contact.warningGlobalContact')}</p>

						<div className="mt-3 flex justify-between">
							<Button text="common.accept" primary onClick={() => setUpdateContact(true)} />

							<Button text="common.decline" danger onClick={() => setShowModal(false)} />
						</div>
					</div>
				}
			/>
		</Box>
	);
};

export default Contacts;
