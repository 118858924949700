import React from 'react';
import Table from '@ssg/common/Components/Table';
import UserContext from '../../../UserContext';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import CatalogCraftsmanModal from './CraftsmanModal';
import CraftsmenFilter from './CraftsmenFilter';
import CraftsmanChangeLog from './CraftsmanChangeLog';
import _ from 'lodash';
import CraftsmanAddExisting from './CraftsmanAddExisting';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import Box from '../../../Components/Layout/Box';
import DeleteCraftsmanModal from './DeleteCraftsmanModal';
import TextButton from '@ssg/common/Components/TextButton';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import GraphQLExtensionsElement from '@ssg/common/Components/GraphQLExtensionsElement';
import TotalCount from '@ssg/common/Components/TotalCount';
import { GraphQLExtensionsData } from '@ssg/common/Components/GraphQLExtensionsContext';
import { faEdit, faEnvelope, faExchange, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { GetCatalogCraftsmen, GetCatalogCraftsmenVariables, GetCatalogCraftsmen_catalogCraftsmen, GetWebCatalogCustomers_catalogCustomers, GetCollectionItems, Permissions } from '../../../GraphQL';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { getCurrentOffset } from '../../../helper';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { CatalogGlobalType, CollectionItemType } from '@ssg/common/GraphQL';
import { SelectOption, dynamicSort } from '@ssg/common/Helpers/Helpers';

const GET_CATALOG_CRAFTSMEN = loader('src/GraphQL/CatalogCraftsmen/GetCatalogCraftsmen.gql');
const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

const FETCH_LIMIT = 20;

interface Props {
	changedBy: string;
	catalogId?: string;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
	craftsmenData?: GetCatalogCraftsmen_catalogCraftsmen[];
}

const Craftsmen: React.FC<Props> = ({ changedBy, catalogId, catalogCustomers, catalogCustomer, craftsmenData }) => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_EDIT]);
	const isExternalUser = userContext.user?.external ?? true;

	const [craftsman, setCraftsman] = React.useState<GetCatalogCraftsmen_catalogCraftsmen>();
	const [craftsmanId, setCraftsmanId] = React.useState<string | undefined>(undefined);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 200);
	const [customerIds, setCustomerIds] = React.useState<null | string[]>(null);

	const {
		loading: loadingCraftsmenList,
		data,
		fetchMore,
	} = useQuery<GetCatalogCraftsmen, GetCatalogCraftsmenVariables>(GET_CATALOG_CRAFTSMEN, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		variables: {
			thisCustomerOnly: isExternalUser,
			searchString: searchTerm.length === 0 ? null : searchTerm,
			limit: FETCH_LIMIT,
			offset: 0,
			customerIds,
		},
		skip: typeof craftsmenData !== 'undefined',
	});

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const { data: collectionItems } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
	});

	const typeDropdownItems: SelectOption[] = React.useMemo(
		() =>
			collectionItems?.collectionItems
				.filter(col => col.type === CollectionItemType.CATALOG_CRAFTSMEN_TYPE)
				.map(type => {
					return { label: type.name, value: type.name };
				})
				.sort(dynamicSort('label')) ?? [],
		[collectionItems],
	);

	const priorityDropdownItems: SelectOption[] = React.useMemo(
		() =>
			collectionItems?.collectionItems
				.filter(col => col.type === CollectionItemType.CATALOG_CRAFTSMEN_PRIORITY)
				.map(type => {
					return { label: type.name, value: type.name };
				})
				.sort(dynamicSort('label')) ?? [],
		[collectionItems],
	);

	const [showModal, setShowModal] = React.useState(false);
	const [updateCraftsman, setUpdateCraftsman] = React.useState(false);
	const [createCraftsman, setCreateCraftsman] = React.useState(false);
	const [deleteCraftsman, setDeleteCraftsman] = React.useState(false);
	const [addExistingCraftsman, setAddExistingCraftsman] = React.useState(false);
	const [viewCraftsmanChanges, setViewCraftsmanChanges] = React.useState(false);

	const craftsmenList = React.useMemo(() => craftsmenData ?? data?.catalogCraftsmen ?? [], [data?.catalogCraftsmen, craftsmenData]);

	const [offset, setOffset] = React.useState(getCurrentOffset((data?.catalogCraftsmen ?? []).length, FETCH_LIMIT));

	const catalogCraftsmenTotalCountName = 'catalogCraftsmenTotalCount';
	const graphQLExtensionsData = React.useContext(GraphQLExtensionsData);
	const totalCatalogCraftsmen = React.useMemo(() => (graphQLExtensionsData[catalogCraftsmenTotalCountName] as number | undefined | null) ?? 0, [graphQLExtensionsData]);

	return (
		<Box full className={catalogId && 'mb-8'} title={catalogId && 'catalog.craftsman.overviewTitle'}>
			<div className="text my-2 flex justify-between">
				<CraftsmenFilter setFilterTerm={setSearchTerm} customerIds={customerIds} setCustomerIds={setCustomerIds} catalogCustomers={catalogCustomers} catalogCustomer={catalogCustomer} />

				{canEdit && (
					<div className="space-y-2">
						<TextButton text="catalog.craftsman.addCraftsman" icon={faPlus} onClick={() => setCreateCraftsman(true)} />
						{catalogId && <TextButton text="catalog.craftsman.addExistingCraftsman" icon={faPlus} onClick={() => setAddExistingCraftsman(true)} />}
					</div>
				)}
			</div>

			<GraphQLExtensionsElement
				name="catalogCraftsmenTotalCount"
				render={value => (
					<TotalCount totalCount={value as number | undefined | null} loading={loadingCraftsmenList} quantityText={t('common.quantity')} entityText={t('catalog.craftsman.overviewTitle')} />
				)}
			/>

			<Table
				data={craftsmenList}
				loading={loadingCraftsmenList}
				columns={[
					{
						label: 'catalog.customer',
						hideColumn: catalogId !== undefined,
						selectFn: c => <p>{c.customer.name}</p>,
						sortFn: (a, b) => a.customer.name.localeCompare(b.customer.name),
					},
					{
						label: 'common.type',
						selectFn: c => <p>{c.type}</p>,
						sortFn: (a, b) => a.type.localeCompare(b.type),
					},
					{
						label: 'catalog.craftsman.contactName',
						selectFn: c => <p>{c.contactName}</p>,
						sortFn: (a, b) => a.contactName.localeCompare(b.contactName),
					},
					{
						label: 'catalog.contact.email',
						selectFn: c => (
							<p>
								<a href={`mailto:${c.email}`} className="hover:underline">
									{c.email}
								</a>
							</p>
						),
						sortFn: (a, b) => (a?.email ?? '').localeCompare(b?.email ?? ''),
					},
					{
						label: 'common.phone',
						noTruncate: true,
						selectFn: c => (
							<div>
								{c.informations.map((inf, index) => (
									<div className="py-1" key={index}>
										<p>
											<a href={`tel:${inf.phoneNumber}`} className="hover:underline">
												{`${formatPhoneNumberIntl(inf.phoneNumber)} - ${inf.remark}`}
											</a>
										</p>
									</div>
								))}
							</div>
						),
					},
					{
						label: 'catalog.craftsman.priority',
						selectFn: c => <p>{c.priority}</p>,
						sortFn: (a, b) => a.priority.localeCompare(b.priority),
					},
					{
						label: 'catalog.attached',
						selectFn: c => (
							<p>{c.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? t('catalog.customer') : c.globalType === CatalogGlobalType.CATALOGS_GLOBAL ? t('catalog.overviewTitle') : ''}</p>
						),
						sortFn: (a, b) =>
							(a.globalType === CatalogGlobalType.CUSTOMER_GLOBAL
								? t('catalog.customer')
								: a.globalType === CatalogGlobalType.CATALOGS_GLOBAL
								  ? t('catalog.overviewTitle')
								  : ''
							).localeCompare(
								b.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? t('catalog.customer') : b.globalType === CatalogGlobalType.CATALOGS_GLOBAL ? t('catalog.overviewTitle') : '',
							),
					},
					{
						label: 'catalog.timedMessage.catalogs',
						noTruncate: true,
						hideColumn: catalogId !== undefined,
						selectFn: c => {
							const catalogString =
								c.catalogs?.map((cat, catIndex) => {
									let catString = `${t('catalog.propertyNumber')}: ${cat.propertyNumber}`;
									if (!cat.addresses) {
										return catString;
									}
									catString += ` - ${t('catalog.addresses')}: `;
									catString += cat.addresses.map(
										(add, index) =>
											`${add.addressLine}, ${add.zipCode}, ${add.city} ${add.startNumber}-${add.endNumber}${cat.addresses && index !== cat.addresses.length - 1 ? ', ' : ''}`,
									);

									if (c.catalogs && c.catalogs.length - 1 !== catIndex) {
										catString += ', ';
									}

									return catString;
								}) ?? '';

							return <p>{catalogString}</p>;
						},
					},
					{
						label: 'common.active',
						classNameTh: 'text-right',
						hideColumn: catalogId !== undefined,
						selectFn: c => <div className="flex content-start justify-end pr-2">{c.active ? t('common.yes') : t('common.no')}</div>,
						sortFn: (a, b) => (a.active ? 'yes' : 'no').localeCompare(b.active ? 'yes' : 'no'),
					},
					{
						label: 'common.changes',
						classNameTh: 'text-right',
						selectFn: c => {
							if (c.version > 1) {
								return (
									<div className="flex content-start justify-end pr-2">
										<FontAwesomeIcon
											icon={faExchange}
											size="lg"
											onClick={() => {
												setCraftsmanId(c.id);
												setViewCraftsmanChanges(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								);
							}
							return <></>;
						},
					},
					{
						label: 'common.edit',
						hideColumn: !canEdit || isExternalUser,
						classNameTh: 'text-right',
						selectFn: c => (
							<div className="flex content-start justify-end text-right">
								<FontAwesomeIcon
									icon={faEdit}
									size="lg"
									onClick={() => {
										setCraftsman(c);

										if (c.global) {
											setShowModal(true);
										} else {
											setUpdateCraftsman(true);
										}
									}}
									className="cursor-pointer"
								/>
							</div>
						),
					},
					{
						label: 'common.remove',
						classNameTh: 'text-right',
						hideColumn: !canEdit,
						selectFn: c => (
							<div>
								<div className="text-red flex content-start justify-end text-right">
									{(!c.global || !catalogId) && (
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setCraftsman(c);
												setDeleteCraftsman(true);
											}}
											className="cursor-pointer"
										/>
									)}
								</div>
							</div>
						),
					},
				]}
				keySelector={c => c.id}
				noDataFoundText={'catalog.craftsman.noCraftsmenFound'}
			/>
			{typeof data?.catalogCraftsmen !== 'undefined' && data?.catalogCraftsmen !== null && totalCatalogCraftsmen > data?.catalogCraftsmen.length && (
				<Button
					fullWidth
					text="common.getMore"
					secondary
					loading={fetchMoreLoading}
					className="mt-2"
					onClick={async () => {
						setOffset(craftsmenList.length);
						setFetchMoreLoading(true);
						await fetchMore({
							variables: {
								offset: craftsmenList.length,
							},
						});
						setFetchMoreLoading(false);
					}}
				/>
			)}
			{typeof craftsmenData !== 'undefined' && (
				<div className="mt-2 ml-2">
					<a
						className="text-sm"
						href={`mailto:${_.toString(
							data?.catalogCraftsmen?.map(ct => {
								if (ct.email) return `${ct?.email};`;
								return '';
							}),
						).replaceAll(',', '')}`}
					>
						<FontAwesomeIcon icon={faEnvelope} /> {t('common.mailAll')}
					</a>
				</div>
			)}

			{/* Craftsman Change Log */}
			{craftsmanId && (
				<CraftsmanChangeLog
					craftsmanId={craftsmanId}
					open={viewCraftsmanChanges}
					close={() => {
						setViewCraftsmanChanges(false);
						setCraftsmanId(undefined);
					}}
				/>
			)}

			{addExistingCraftsman && typeof catalogCustomer !== 'undefined' && catalogId && (
				<CraftsmanAddExisting
					catalogId={catalogId}
					catalogCustomer={catalogCustomer}
					changedBy={changedBy}
					open={addExistingCraftsman}
					close={() => setAddExistingCraftsman(false)}
					catalogCraftsmenId={craftsmenData?.map(c => c.id) ?? []}
				/>
			)}

			{/* Catalog Contact Modal */}

			{createCraftsman && (
				<CatalogCraftsmanModal
					changedBy={changedBy}
					catalogId={catalogId}
					catalogCustomer={catalogCustomer}
					catalogCustomers={catalogCustomers}
					typeDropdownItems={typeDropdownItems}
					priorityDropdownItems={priorityDropdownItems}
					open={createCraftsman}
					close={() => setCreateCraftsman(false)}
					fetchVariables={{
						thisCustomerOnly: isExternalUser,
						searchString: searchTerm.length === 0 ? null : searchTerm,
						limit: FETCH_LIMIT,
						offset: offset,
						customerIds,
					}}
				/>
			)}

			{updateCraftsman && craftsman && (
				<CatalogCraftsmanModal
					changedBy={changedBy}
					catalogId={catalogId}
					catalogCustomer={catalogCustomer}
					catalogCustomers={catalogCustomers}
					typeDropdownItems={typeDropdownItems}
					priorityDropdownItems={priorityDropdownItems}
					open={updateCraftsman}
					close={() => {
						setShowModal(false);
						setUpdateCraftsman(false);
					}}
					edit
					data={craftsman}
					fetchVariables={{
						thisCustomerOnly: isExternalUser,
						searchString: searchTerm.length === 0 ? null : searchTerm,
						limit: FETCH_LIMIT,
						offset: offset,
						customerIds,
					}}
				/>
			)}

			{deleteCraftsman && craftsman && (
				<DeleteCraftsmanModal
					changedBy={changedBy}
					catalogId={catalogId}
					visible={deleteCraftsman}
					close={() => setDeleteCraftsman(false)}
					craftsmanData={craftsman}
					isExternalUser={isExternalUser}
				/>
			)}

			<Modal
				title={t('common.editGlobal')}
				size={ModalSize.SMALL}
				visible={showModal}
				close={() => setShowModal(false)}
				body={
					<div>
						<strong>{t('catalog.craftsman.editGlobalCraftman')}</strong>
						<p>{t('catalog.craftsman.warningGlobalCraftman')}</p>

						<div className="mt-3 flex justify-between">
							<Button text="common.accept" primary onClick={() => setUpdateCraftsman(true)} />

							<Button text="common.decline" danger onClick={() => setShowModal(false)} />
						</div>
					</div>
				}
			/>
		</Box>
	);
};

export default Craftsmen;
