import React from 'react';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';
import Modal from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { CaseEconomicsOverviewDocument, CaseEconomicsOverviewQuery, CaseEconomicsOverviewQueryVariables, GetCaseEconomySpecQuery, Permissions, useCaseEconomicsOverviewQuery, useGetAdminSettingsQuery, useGetCaseEconomySpecQuery, useSetCostEstimateMutation, useSetSsgPriceMutation, useUpdateCaseMutation } from '@ssg/common/GraphQL/indexV2';

interface Props {
	caseId: string;
	caseErpId: string;
	caseErpNo: string;
	fixedPriceOnCase: number;
}

interface SSGPriceInput {
	price: number;
}

interface HourlyRatePriceInput {
	hourlyRatePrice: number;
}

interface CostEstimateInput {
	costEstimate: number;
}

type combinedEconomicsData = {
	name: string;
	cost: number;
	sale: number;
	coverage: number;
};

function editEconomicData(s: GetCaseEconomySpecQuery['caseEconomicsSpec'] | null) {
	const array: combinedEconomicsData[] = [];
	const cost = s?.cost ?? [];
	const sale = s?.sale ?? [];

	for (let i = 0; i < cost?.length; i++) {
		array[i] = { name: '', cost: 0, sale: 0, coverage: 0 };
		array[i].name = cost[i].name ?? '';
		array[i].cost = cost[i].value ?? 0;

		if (cost[i].name === sale[i].name) {
			array[i].sale = sale[i].value ?? 0;
		}

		//calculate coverage
		// 100 * (S - C) / S
		array[i].coverage = (100 * (sale[i].value - cost[i].value)) / sale[i].value;

		if (isNaN(array[i].coverage)) {
			array[i].coverage = 0;
		}
	}

	return array;
}

const EconomyTab: React.FC<Props> = ({ caseId, caseErpId, caseErpNo, fixedPriceOnCase }): React.ReactElement => {
	const { t } = useTranslation();

	const lockCaseHourlyRate = useFlag(FeatureFlagEnums.LOCK_CASE_HOURLY_RATE);
	const minCaseHourlyRateFlag = useFlag(FeatureFlagEnums.ADMIN_SET_MIN_CASE_HOURLY_RATE);
	const IGVA2024 = useFlag(FeatureFlagEnums.IGVA2024);

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];

	const canSetSSGPrice = arraysHasMatchingValue(userPermissions as unknown as Permissions[], [Permissions.CasesEconomyInvoicesEdit]);

	const [showSSGsalesPrice, setShowSSGSalesPrice] = React.useState(false);
	const [showLockHourlyRateOnCase, setShowLockHourlyRateOnCase] = React.useState(false);
	const [minimumHourlyRate, setMinimumHourlyRate] = React.useState(0);
	const [showBelforCostPriceInput, setShowBelforCostPriceInput] = React.useState(false);

	useGetAdminSettingsQuery({
		variables: {
			type: 'INVOICE',
		},
		onCompleted(data) {
			if ('minHourlyRate' in data.setting[0]) {
				setMinimumHourlyRate(data.setting[0].minHourlyRate ?? 0);
			}
		},
	});

	const { data, loading } = useCaseEconomicsOverviewQuery({
		context: { debatch: true },
		variables: {
			caseERPReferenceNo: caseErpNo,
		},
		fetchPolicy: 'cache-and-network',
	});

	const { data: economySpec, loading: specLoading } = useGetCaseEconomySpecQuery({
		context: { debatch: true },
		variables: {
			erpReferenceId: caseErpId,
		},
	});

	const [setSSGPrice, { loading: ssgPriceLoading }] = useSetSsgPriceMutation();

	const [setCostEstimate, { loading: costEstimateLoading }] = useSetCostEstimateMutation();

	const [updateCase, { loading: updateCaseSubmitting }] = useUpdateCaseMutation();

	const economicsData = React.useMemo(() => {
		if (typeof data === 'undefined') return [];
		const useForExpectedCoverageCalc = data.caseEconomicsOverview.wipLimit > 0 ? data.caseEconomicsOverview.wipLimit : data.caseEconomicsOverview.salesTotal;
		const extpectedCoverage = ((useForExpectedCoverageCalc - data.caseEconomicsOverview.costTotal) / useForExpectedCoverageCalc) * 100;
		return [
			{
				name: 'case.economy.costPrice',
				value: data.caseEconomicsOverview.costTotal,
			},
			{
				name: 'case.economy.sale',
				value: data.caseEconomicsOverview.salesTotal,
			},
			{
				name: 'case.economy.invoiced',
				value: data.caseEconomicsOverview.invoicedTotal,
			},
			{
				name: 'case.economy.offer/screening',
				value: data.caseEconomicsOverview.screening,
			},
			{
				name: 'case.economy.profit',
				value: data.caseEconomicsOverview.profit,
			},
			{
				name: 'case.economy.expectedCoverage',
				value: `${extpectedCoverage.toFixed(0)}%`,
			},
			{
				name: 'case.economy.coverage',
				value: data.caseEconomicsOverview.coverageRatio.toFixed(0) + '%',
			},
			{
				name: 'case.economy.delta',
				value: data.caseEconomicsOverview.deltaNotInvoiced,
			},
		];
	}, [data]);

	const { handleSubmit, register } = useForm<SSGPriceInput>({
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: SSGPriceInput) => {
		try {
			await setSSGPrice({
				variables: {
					erpNo: caseErpNo,
					price: parseFloat(data.price.toString()),
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}
					const cachedRequest = cache.readQuery<CaseEconomicsOverviewQuery, CaseEconomicsOverviewQueryVariables>({
						query: CaseEconomicsOverviewDocument,
						variables: {
							caseERPReferenceNo: caseErpNo,
						},
					});

					if (cachedRequest === null || cachedRequest.caseEconomicsOverview === null) {
						return;
					}

					cache.writeQuery<CaseEconomicsOverviewQuery, CaseEconomicsOverviewQueryVariables>({
						query: CaseEconomicsOverviewDocument,
						variables: {
							caseERPReferenceNo: caseErpNo,
						},
						data: {
							caseEconomicsOverview: {
								...cachedRequest.caseEconomicsOverview,
								quote: cacheData.setSSGPrice,
								wipLimit: cacheData.setSSGPrice,
							},
						},
					});
				},
			});
			setShowSSGSalesPrice(false);
		} catch (e) {
			console.log(e);
		}
	};

	const { handleSubmit: handleCaseFixedPrice, register: registerCaseFixedPrice } = useForm<HourlyRatePriceInput>({
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmitCaseFixedPrice = async (data: HourlyRatePriceInput) => {
		try {
			await updateCase({
				variables: {
					id: caseId,
					fixedPriceOnCase: parseFloat(data.hourlyRatePrice.toString()),
				},
			});
			setShowLockHourlyRateOnCase(false);
		} catch (e) {
			console.log(e);
		}
	};

	const { handleSubmit: handleCostEstimate, register: registerCostEstimate } = useForm<CostEstimateInput>({
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmitCostEstimate = async (data: CostEstimateInput) => {
		try {
			await setCostEstimate({
				variables: {
					erpNo: caseErpNo,
					price: parseFloat(data.costEstimate.toString()),
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}
					const cachedRequest = cache.readQuery<CaseEconomicsOverviewQuery, CaseEconomicsOverviewQueryVariables>({
						query: CaseEconomicsOverviewDocument,
						variables: {
							caseERPReferenceNo: caseErpNo,
						},
					});

					if (cachedRequest === null || cachedRequest.caseEconomicsOverview === null) {
						return;
					}

					cache.writeQuery<CaseEconomicsOverviewQuery, CaseEconomicsOverviewQueryVariables>({
						query: CaseEconomicsOverviewDocument,
						variables: {
							caseERPReferenceNo: caseErpNo,
						},
						data: {
							caseEconomicsOverview: {
								...cachedRequest.caseEconomicsOverview,
								quote: cacheData.setCostEstimate,
								costEstimate: cacheData.setCostEstimate,
							},
						},
					});
				},
			});
			setShowBelforCostPriceInput(false);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<div className="relative flex w-full flex-row pr-4">
				<div className="flex-grow-default mr-4">
					<Table
						data={editEconomicData(economySpec?.caseEconomicsSpec ?? null)}
						keySelector={m => m.name}
						noDataFoundText="No data"
						loading={loading || specLoading}
						columns={[
							{
								label: 'common.description',
								selectFn: m => <div>{m.name}</div>,
							},
							{
								label: 'case.economy.cost',
								selectFn: m => addThousandSeperator(m.cost),
							},
							{
								label: 'case.economy.sale',
								selectFn: m => addThousandSeperator(m.sale),
							},
							{
								label: 'case.economy.coverage',
								selectFn: m => <div>{m.coverage.toFixed(0)}%</div>,
							},
						]}
					/>
				</div>

				<div className="flex-shrink-default sticky top-0 mt-11 h-64">
					<table className="text-sm">
						<thead>
							<tr>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{economicsData.map(d => (
								<tr key={d.name}>
									<td className="py-1">{t(d.name)}:</td>
									<td className="py-1 text-right">{typeof d.value === 'number' ? addThousandSeperator(d.value) : d.value}</td>
								</tr>
							))}
							{typeof data !== 'undefined' && (
								<tr>
									<td className="py-1">
										<p className="flex items-baseline">
											{t('case.economy.ssgSale')}
											{canSetSSGPrice && <TextButton icon={faEdit} onClick={() => setShowSSGSalesPrice(true)} />}
										</p>
									</td>
									<td className="py-1 text-right">{data.caseEconomicsOverview.wipLimit > 0 ? addThousandSeperator(data.caseEconomicsOverview.wipLimit) : '-'}</td>
								</tr>
							)}
							{(lockCaseHourlyRate && typeof data !== 'undefined') && (
								<tr>
									<td className="py-1">
										<p className="flex items-baseline">
											{t('case.economy.lockHourlyRate')}
											{canSetSSGPrice && <TextButton icon={faEdit} onClick={() => setShowLockHourlyRateOnCase(true)} />}
										</p>
									</td>
									<td className="py-1 text-right">{fixedPriceOnCase > 0 ? addThousandSeperator(fixedPriceOnCase) : '-'}</td>
								</tr>
							)}
							{(IGVA2024 && typeof data !== 'undefined') && (
								<>
									<tr>
										<td className="py-1">
											<p className="flex items-baseline">
												{t('case.economy.belforCostPrice')}
												{canSetSSGPrice && <TextButton icon={faEdit} onClick={() => setShowBelforCostPriceInput(true)} />}
											</p>
										</td>
										<td className="py-1 text-right">{data.caseEconomicsOverview.costEstimate > 0 ? addThousandSeperator(data.caseEconomicsOverview.costEstimate) : '-'}</td>
									</tr>
									<tr>
										<td className="py-1">
											<p className="flex items-baseline">
												{t('case.economy.poc')}
											</p>
										</td>
										<td className="py-1 text-right">{data.caseEconomicsOverview.percentageOfCompletion > 0 ? `${data.caseEconomicsOverview.percentageOfCompletion.toFixed(1)}%` : '-'}</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<Modal
				visible={showSSGsalesPrice}
				close={() => setShowSSGSalesPrice(false)}
				title="case.economy.setSsgSale"
				body={
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input name="price" title="case.economy.ssgSale" innerRef={register} type="number" required step={0.01} lang="da" inputMode="decimal" />
						<div className="mt-3">
							<Button submit success text="case.economy.setSsgSale" loading={ssgPriceLoading} />
						</div>
					</form>
				}
			/>

			<Modal
				visible={showLockHourlyRateOnCase}
				close={() => setShowLockHourlyRateOnCase(false)}
				title="case.economy.lockHourlyRate"
				body={
					<form onSubmit={handleCaseFixedPrice(onSubmitCaseFixedPrice)}>
						<Input
							name="hourlyRatePrice"
							title="case.economy.hourlyRateOnCase"
							innerRef={registerCaseFixedPrice}
							type="number"
							required
							step={0.01}
							lang="da"
							inputMode="decimal"
							min={minimumHourlyRate}
						/>
						{(minCaseHourlyRateFlag && (minimumHourlyRate > 0)) && <p>{t('case.economy.minHourlyRate')}: {minimumHourlyRate}</p>}
						<div className="mt-3">
							<Button submit success text="case.economy.setHourlyRate" loading={updateCaseSubmitting} />
						</div>
					</form>
				}
			/>

			<Modal
				visible={showBelforCostPriceInput}
				close={() => setShowBelforCostPriceInput(false)}
				title="case.economy.belforCostPrice"
				body={
					<form onSubmit={handleCostEstimate(onSubmitCostEstimate)}>
						<Input name="costEstimate" title="case.economy.belforCostPrice" innerRef={registerCostEstimate} type="number" required step={0.01} lang="da" inputMode="decimal" />
						<div className="mt-3">
							<Button submit success text="case.economy.setBelforCostPrice" loading={costEstimateLoading} />
						</div>
					</form>
				}
			/>
		</>
	);
};

export default EconomyTab;
