import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faCheck, faCheckCircle, faEnvelope, faExternalLink, faFileArchive, faFileDownload, faFileUpload, faSpinner, faSync, faTimes, faTimesCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appendNo, fileListToFileInputList } from '../../../helper';
import { useFlag } from '@unleash/proxy-client-react';
import { toBase64 } from '@ssg/common/Helpers/inputFileHelper';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import ImageGallery from 'react-image-gallery';
import Box from '../../../Components/Layout/Box';
import Header from '@ssg/common/Components/Header';
import Loading from '@ssg/common/Components/Loading';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import FileItem from './FileItem';
import TextButton from '@ssg/common/Components/TextButton';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import classNames from 'classnames';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { FileImage } from './FileImage';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { Permissions, GetCaseEsdhFilesDocument, GetSingleCaseQuery, useDeleteCaseFileMutation, useGetCaseEsdhFilesQuery, useGetSingleCaseQuery, useLogCaseFileChangeMutation, useUploadCaseFilesMutation, useGetWebCaseFileQuery, GetWebCaseFileQuery, GetCaseEsdhFilesQuery, GetCaseEsdhFilesQueryVariables, FileActionType } from '@ssg/common/GraphQL/indexV2';

interface FileProps {
	fileName: string;
	folder?: string;
}

interface Metadata {
	key: string;
	value: string;
}

interface UploadFileAndData {
	fileName: string;
	fileData: string;
}

interface UploadFile extends UploadFileAndData {
	uploading: 'Uploading' | 'Success' | 'Error';
}

type ResponseStatus = 'Uploading' | 'Success' | 'Error';

interface ResponseMessage {
	name: string;
	status: ResponseStatus;
}
// https://ssgcloud.sharepoint.com/sites/SSGESDH/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FSSGESDH%2FShared%20Documents%2FSager%2FCS000639%2FVS%20Ny%20besked%20pa%CC%8A%20sag%20D0616732%2D206164%3B%20Kirkev%C3%A6nget%2039%20Kirke%20Stillinge%204200%20Slagelse%20%201%2Emsg&parent=%2Fsites%2FSSGESDH%2FShared%20Documents%2FSager%2FCS000639
// https://ssgcloud.sharepoint.com/sites/SSGESDH/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FSSGESDH%2FShared%20Documents%2FSager%2F%2FVS%20Ny%20besked%20pa%CC%8A%20sag%20D0616732%2D206164%3B%20Kirkev%C3%A6nget%2039%20Kirke%20Stillinge%204200%20Slagelse%20%201%2Emsg&parent=%2Fsites%2FSSGESDH%2FShared%20Documents%2FSager%2F
//https://ssgcloud.sharepoint.com/sites/SSGESDH/Shared%20Documents/Sager/CS000639/VS%20Ny%20besked%20pa%CC%8A%20sag%20D0616732-206164;%20Kirkev%C3%A6nget%2039%20Kirke%20Stillinge%204200%20Slagelse%20%201.msg

// VS%20Ny%20besked%20pa%CC%8A%20sag%20D0616732-206164;%20Kirkev%C3%A6nget%2039%20Kirke%20Stillinge%204200%20Slagelse%20%201
// VS%20Ny%20besked%20pa%CC%8A%20sag%20D0616732%2D206164%3B%20Kirkev%C3%A6nget%2039%20Kirke%20Stillinge%204200%20Slagelse%20%201
const createSPShareLink = (caseNo: string, documentName: string) => {
	return `https://ssgcloud.sharepoint.com/sites/SSGESDH/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FSSGESDH%2FShared%20Documents%2FSager%2F${caseNo}%2F${encodeURIComponent(documentName)}&parent=%2Fsites%2FSSGESDH%2FShared%20Documents%2FSager%2F${caseNo}`;

};

const FileOverview: React.FC = (): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);

	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const galleryRef = React.useRef<ImageGallery>(null);

	const zippedCaseImagesFlag = useFlag(FeatureFlagEnums.ZIPPED_CASE_IMAGES);
	const hrefImageDownload = useFlag(FeatureFlagEnums.SINGLE_CASE_HREF_IMAGE_DOWNLOAD);
	const imageCategorizationUI = useFlag(FeatureFlagEnums.IMAGE_CATEGORIZATION_UI);

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const caseViewExternal = arraysHasMatchingValue(userPermissions as unknown as Permissions[], [Permissions.CasesViewExternal]);
	const isExternalUser = userContext.user?.external ?? true;

	const { data, loading: loadingData, refetch } = useGetCaseEsdhFilesQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			id: id,
		},
		skip: id === '',
	});

	const { data: caseData } = useGetSingleCaseQuery({
		fetchPolicy: 'cache-first',
		variables: {
			id: id,
			includeEconomy: false,
		},
	});

	const [deleteCaseFile, { loading: loadingDeleteFile }] = useDeleteCaseFileMutation();
	const [uploadCaseFiles, { loading: loadingUploadFiles }] = useUploadCaseFilesMutation();

	const [showGallery, setShowGallery] = React.useState(false);
	const [fileData, setFileData] = React.useState<FileProps>({
		fileName: '',
		folder: '',
	});
	const [galleryIndex, setGalleryIndex] = React.useState(0);
	const [fileToDelete, setFileToDelete] = React.useState<string>();
	const [fileToDownload, setFileToDownload] = React.useState<GetWebCaseFileQuery['caseFile']>();

	const { data: file, loading: fileLoading } = useGetWebCaseFileQuery({
		variables: {
			fileName: fileData.fileName,
			folder: fileData.folder,
		},
		skip: fileData.fileName === '',
	});

	React.useEffect(() => setFileToDownload(file?.caseFile), [file?.caseFile]);

	const [logFileChange] = useLogCaseFileChangeMutation();

	const isImage = (extension: string): boolean => {
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'tif', 'tiff', 'webp', 'heic'];
		return imageExtensions.includes(extension);
	};

	const isVideo = (extension: string): boolean => {
		const imageExtensions = ['mp4'];
		return imageExtensions.includes(extension);
	};
	const isOffer = (groupingKey: string): boolean => {
		return groupingKey.startsWith('Q');
	};

	const isScreening = (groupingKey: string): boolean => {
		return groupingKey.startsWith('SCR');
	};

	const isEmail = (extension: string): boolean => {
		const emailExtensions = ['eml', 'msg'];
		return emailExtensions.includes(extension);
	};

	const isReport = (name: string): boolean => {
		return name.startsWith('Rapporter');
	};
	const isReportType = (metadata: Array<Metadata>): boolean => {
		return metadata.some(o => o.key === 'DokumentType' && o.value === 'Rapport');
	};

	const allImages = React.useMemo(() => data?.case.documents.filter(f => isImage(f.extension)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [data]);
	const caseImages = React.useMemo(() => allImages.filter(img => img.metadata.filter(m => m.key === 'K_x00f8_reseddel_x0020_nr').length === 0), [allImages]);
	const drivingslipImages = React.useMemo(
		() =>
			allImages
				.filter(img => img.metadata.filter(m => m.key === 'K_x00f8_reseddel_x0020_nr').length > 0)
				.map(di => ({
					fileId: di.fileId,
					name: di.name,
					drivingslipId: di.metadata.find(m => m.key === 'K_x00f8_reseddel_x0020_nr')?.value,
					questionnaireSection: di.metadata.find(m => m.key === 'QuestionnaireSection')?.value,
					original: di.thumbnail,
					thumbnail: di.thumbnail,
					description: di.name,
					comment: di.comments,
					url: di.url,
				})),
		[allImages],
	);

	interface File {
		fileId: string;
		name: string;
		drivingslipId: string | undefined;
		questionnaireSection: string | undefined;
		original: string;
		thumbnail: string;
		description: string;
		comment: string | null;
		url: string;
	}

	interface GroupedFiles {
		[drivingslipId: string]: {
			[questionnaireSection: string]: File[];
		};
	}

	// First group by drivingslip then questionnaire section
	const groupedFiles = drivingslipImages.reduce((result, file) => {
		const { drivingslipId, questionnaireSection } = file;

		// Create or update the drivingslipId group
		(result[drivingslipId || ''] = result[drivingslipId || ''] || {}) as {
			[questionnaireSection: string]: File[];
		};

		// Create or update the QuestionnaireSection group within the drivingslipId group
		(result[drivingslipId || ''][questionnaireSection || ''] = result[drivingslipId || ''][questionnaireSection || ''] || []) as File[];

		// Add the file to the group
		result[drivingslipId || ''][questionnaireSection || ''].push(file);

		return result;
	}, {} as GroupedFiles);

	const videos = React.useMemo(() => data?.case.documents.filter(f => isVideo(f.extension)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [data]);
	const gallery = React.useMemo(
		() =>
			allImages.map(image => ({
				original: image.thumbnail,
				thumbnail: image.thumbnail,
				thumbnailClass: 'w-20 h-full',
				description: image.name,
				comment: image.comments,
				url: image.url,
			})),
		[allImages],
	);
	const offerFiles = React.useMemo(() => data?.case.documents.filter(f => isOffer(f.groupingKey)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [data]);
	const screeningFiles = React.useMemo(() => data?.case.documents.filter(f => isScreening(f.groupingKey)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [data]);
	const otherFiles = React.useMemo(
		() =>
			data?.case.documents
				.filter(f => !isScreening(f.groupingKey) && !isImage(f.extension) && !isReport(f.name) && !isReportType(f.metadata) && !isOffer(f.groupingKey) && !isEmail(f.extension))
				.sort((a, b) => b.created.localeCompare(a.created)) ?? [],
		[data],
	);
	const emails = React.useMemo(() => data?.case.documents.filter(f => isEmail(f.extension)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [data]);
	const reports = React.useMemo(() => data?.case.documents.filter(f => isReport(f.name) || isReportType(f.metadata)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [data]);

	const deleteFile = async (fileName: string) => {
		await deleteCaseFile({
			variables: {
				fileName: fileName,
				folder: data?.case.erpNo ?? '',
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetCaseEsdhFilesQuery, GetCaseEsdhFilesQueryVariables>({
					query: GetCaseEsdhFilesDocument,
					variables: {
						id: id,
					},
				});

				if (cachedRequest === null || cachedRequest.case === null) {
					return;
				}

				cache.writeQuery<GetCaseEsdhFilesQuery, GetCaseEsdhFilesQueryVariables>({
					query: GetCaseEsdhFilesDocument,
					variables: {
						id: id,
					},
					data: {
						case: {
							...cachedRequest.case,
							documents: cachedRequest.case.documents.filter(doc => doc.name !== fileName),
						},
					},
				});
			},
		});
		setFileToDelete(undefined);
		await logFileChange({
			variables: {
				filename: fileName,
				case: id,
				action: FileActionType.Remove,
			},
		});
	};

	const [uploadingFiles, setUploadingFiles] = React.useState<UploadFile[]>([]);
	const [preparingFiles, setPreparingFiles] = React.useState<boolean>(false);

	async function uploadFilesNew(retryFiles: UploadFileAndData[], e?: React.ChangeEvent<HTMLInputElement>) {
		if (typeof data === 'undefined') {
			return;
		}
		setPreparingFiles(true);
		setFilesUploaded(undefined);
		setFilesError([]);
		const returnValues: UploadFileAndData[] = retryFiles;
		if (returnValues.length === 0) {
			if (!e?.target.files || e.target.files.length === 0) {
				alert(t('error.fileMissing'));
				return;
			}
			for (let i = 0; i < e.target.files.length; i++) {
				const file = e.target.files[i];

				const fileAsString = await toBase64(file);

				returnValues.push({
					fileData: fileAsString.split(',')[1],
					fileName: file.name,
				});
			}
		}

		const existingFileNames = data?.case.documents.map(d => d.name) ?? [];

		for (const file of returnValues) {
			if (existingFileNames.includes(file.fileName)) {
				file.fileName = appendNo(file.fileName, existingFileNames);
			}
			existingFileNames.push(file.fileName);
		}

		setUploadingFiles(
			returnValues.map(v => ({
				fileName: v.fileName,
				uploading: 'Uploading',
				fileData: '',
			})),
		);
		setPreparingFiles(false);
		returnValues.forEach(async fileValue => {
			const response = await fetch(envVar.baseUrl + '/uploadFile', {
				headers: {
					upKey: '!q3jUZ6qh/vuEs!bdp004#NKX8_2d1XLm#4xgo#2U3Z4OUrh!rhr/2rLryMW-g30j6%E9SRZD%R4m5',
					'content-type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({
					//file: fileValue,
					folderName: data.case.erpNo,
					fileName: fileValue.fileName,
					fileData: fileValue.fileData,
				}),
			});

			let status: ResponseStatus;

			if (response.ok) {
				const responseMessage: ResponseMessage = await response.json();
				status = responseMessage.status;
			} else {
				status = 'Error';
			}

			setUploadingFiles(current => {
				const index = current.findIndex(s => s.fileName === fileValue.fileName);
				if (index === -1) {
					return current;
				}
				return [
					...current.slice(0, index),
					{
						...current[index],
						uploading: status,
						fileData: fileValue.fileData,
					},
					...current.slice(index + 1),
				];
			});

			// await refetch({
			//     id: id,
			// });
		});

		await logFileChange({
			variables: {
				filename: returnValues.map(val => `${val.fileName}`).join(', '),
				case: id,
				action: FileActionType.Add,
			},
		});
		// await refetch({
		//     id: id,
		// });
	}

	const [filesUploaded, setFilesUploaded] = React.useState<number | undefined>(undefined);
	const [filesError, setFilesError] = React.useState<UploadFileAndData[]>([]);

	React.useEffect(() => {
		async function letsRefetch() {
			await refetch({
				id: id,
			});
		}

		if (uploadingFiles.length > 0 && uploadingFiles.length === uploadingFiles.filter(f => f.uploading !== 'Uploading').length) {
			const filesUploadedCount = uploadingFiles.filter(uf => uf.uploading !== 'Error').length;
			const filesError = uploadingFiles
				.filter(uf => uf.uploading === 'Error')
				.map<UploadFileAndData>(f => ({
					fileName: f.fileName,
					fileData: f.fileData,
				}));
			const allDocNames = data?.case.documents.map(d => d.name) ?? [];
			const fileNotLoaded = uploadingFiles.filter(uf => uf.uploading !== 'Error').find(up => !allDocNames.includes(up.fileName));
			if (typeof fileNotLoaded === 'undefined') {
				setFilesUploaded(filesUploadedCount);
				setFilesError(filesError);
				setUploadingFiles([]);
			} else {
				letsRefetch();
			}
		}
	}, [data?.case.documents, id, refetch, uploadingFiles]);

	async function uploadFiles(e: React.ChangeEvent<HTMLInputElement>) {
		if (!e.target.files || e.target.files.length === 0) {
			alert(t('error.fileMissing'));
			return;
		}

		const fileInputs = await fileListToFileInputList(e.target.files);

		const existingFileNames = data?.case.documents.map(d => d.name) ?? [];

		for (const file of fileInputs) {
			if (existingFileNames.includes(file.filename)) {
				file.filename = appendNo(file.filename, existingFileNames);
			}
			existingFileNames.push(file.filename);
		}

		try {
			await uploadCaseFiles({
				variables: {
					files: fileInputs,
					folder: data?.case.erpNo ?? '',
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCaseEsdhFilesQuery, GetCaseEsdhFilesQueryVariables>({
						query: GetCaseEsdhFilesDocument,
						variables: {
							id: id,
						},
					});

					if (cachedRequest === null || cachedRequest.case.documents === null) {
						return;
					}

					cache.writeQuery<GetCaseEsdhFilesQuery, GetCaseEsdhFilesQueryVariables>({
						query: GetCaseEsdhFilesDocument,
						variables: {
							id: id,
						},
						data: {
							case: {
								...cachedRequest.case,
								documents: [...cachedRequest.case.documents, ...cacheData.uploadCaseFiles],
							},
						},
					});
				},
			});

			await logFileChange({
				variables: {
					filename: fileInputs.map(val => `${val.filename}`).join(', '),
					case: id,
					action: FileActionType.Add,
				},
			});
			setFileToDelete(undefined);
		} catch (e) {
			console.error(e);
		}
	}

	React.useEffect(() => {
		if (typeof fileToDownload !== 'undefined') {
			const url = window.URL.createObjectURL(new Blob([new Uint8Array(fileToDownload.fileData)]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${fileToDownload.filename}`);
			// 3. Append to html page
			document.body.appendChild(link);
			// 4. Force download
			link.click();
			// 5. Clean up and remove the link
			link.parentNode && link.parentNode.removeChild(link);
			setFileToDownload(undefined);
		}
	}, [fileToDownload]);

	const [selectedFileIds, setSelectedFileIds] = React.useState<string[]>([]);
	const onSelectFile = (checked: boolean, id: string) =>
		setSelectedFileIds(current => {
			const without = current.filter(x => x !== id);
			if (!checked) {
				return without;
			}

			return [...without, id];
		});

	const [gettingZip, setGettingZip] = React.useState<{ loading: boolean; type?: 'all' | 'selected' }>({ loading: false });
	const getZippedCaseImagesRest = async (fileIdsToInclude?: string[] | null) => {
		if (typeof data?.case !== 'undefined') {
			setGettingZip({ loading: true, type: fileIdsToInclude ? 'selected' : 'all' });

			const blobResponse = await fetch(envVar.baseUrl + '/zippedCaseImages', {
				headers: {
					'Content-Type': 'application/json',
					zipKey: '!q3jUZ6qh/vuEs!bdp004#NKX8_2d1XLm#4xgo#2U3Z4OUrh!rhr/2rLryMW-g30j6%E9SRZD%R4m5',
				},
				method: 'POST',
				body: JSON.stringify({
					caseNo: data.case.erpNo,
					fileIdsToInclude: fileIdsToInclude,
				}),
			}).then(res => res.blob());

			if (blobResponse.type === 'application/zip' && blobResponse.size > 0) {
				const url = window.URL.createObjectURL(blobResponse);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${data.case.erpNo} ${dateToDateTimeString(new Date())}.zip`);
				// 3. Append to html page
				document.body.appendChild(link);
				// 4. Force download
				link.click();
				// 5. Clean up and remove the link
				link.parentNode && link.parentNode.removeChild(link);
			} else {
				alert('Kunne ikke hente zip filen');
			}

			setGettingZip({ loading: false });
		}
	};

	const findDrivingSlipById = (drivingslipId: string | undefined): string => {
		let result: GetSingleCaseQuery['case']['drivingSlipSeries'][number]['drivingSlips'][number] | undefined;
		let uniqueDriversToString = '';
	
		caseData?.case.drivingSlipSeries.forEach(series => {
			const drivingSlip = series.drivingSlips.find(d => d.id === drivingslipId || d.series === drivingslipId);
			const drivers = series.drivingSlips.flatMap(d => d.driver?.name);
	
			const uniqueDrivers = new Set(drivers);
			const uniqueDriversArray = Array.from(uniqueDrivers);
			const currentUniqueDriversToString = uniqueDriversArray.join(', ');
	
			if (drivingSlip) {
				result = drivingSlip;
				uniqueDriversToString = currentUniqueDriversToString;
			}
		});
	
		return `${t('common.drivingSlip')} ${t('drivingSlips.drivenBy')} ${uniqueDriversToString || '-'} ${result?.end !== undefined ? formatDate(new Date(result?.end)) : '-'}`;
	};

	return (
		<>
			<Header title={`${t('case.documentsAndPicturesFor')} ${data?.case.erpNo ?? ''}`} backButton>
				{!caseViewExternal && (
					<>
						<div>
							<div>
								<FormFieldHeader htmlFor="documentUpload" title="common.uploadFiles" />
								<div className="flex items-center">
									<label className="flex flex-col items-center p-1 px-3 py-2 text-white cursor-pointer rounded-default bg-blue focus:outline-none">
										<span>
											<FontAwesomeIcon
												icon={uploadingFiles.length > 0 ? faSpinner : faFileUpload}
												className={classNames('mr-2', {
													'animate-spin': uploadingFiles.length > 0,
												})}
											/>
											{uploadingFiles.length > 0
												? `${t('case.uploadingFiles')} (${uploadingFiles.filter(f => f.uploading === 'Success').length}/${uploadingFiles.length})`
												: t('case.chooseFiles')}
										</span>
										<input
											name="documentUpload"
											id="documentUpload"
											type="file"
											autoComplete="nope"
											className="hidden"
											onChange={e => uploadFilesNew([], e)}
											multiple
											disabled={uploadingFiles.length > 0}
										/>
									</label>
								</div>
							</div>
							<div>
								<div className="flex flex-row flex-wrap w-2/3 mt-2 text-blue">
									{uploadingFiles.map((uF, index) => (
										<div className="flex flex-row items-center mr-2" key={uF.fileName + index}>
											<p className="text-sm">{uF.fileName}</p>
											<FontAwesomeIcon
												icon={uF.uploading === 'Uploading' ? faSpinner : uF.uploading === 'Success' ? faCheck : faTimes}
												size="1x"
												className={classNames('mr-2 ml-1', {
													'animate-spin': uF.uploading === 'Uploading',
													'text-red': uF.uploading === 'Error',
												})}
											/>
										</div>
									))}
									{typeof filesUploaded !== 'undefined' && (
										<div>
											<div className="p-2 mb-2 bg-opacity-25 rounded-md cursor-pointer bg-green border-green-dark border-1" onClick={() => setFilesUploaded(undefined)}>
												<p className="font-semibold text-green-dark">
													<FontAwesomeIcon icon={faCheckCircle} className="mr-3 text-green-dark" size="lg" />
													{filesUploaded} {filesUploaded === 1 ? t('case.fileUploaded') : t('case.filesUploaded')}
												</p>
											</div>
											{filesError.length > 0 && (
												<div className="text-sm">
													<p className="text-sm">{t('case.filesError')}</p>
													<div className="flex flex-row flex-wrap font-bold">
														{filesError.map((file, index) => (
															<p className="text-red" key={file.fileName + index}>
																{file.fileName}
															</p>
														))}
													</div>
													<TextButton icon={faSync} text="Prøv igen" onClick={() => uploadFilesNew(filesError)} />
												</div>
											)}
										</div>
									)}
									{preparingFiles && (
										<p>
											<FontAwesomeIcon icon={faSpinner} className="mr-3 animate-spin" size="lg" />
											{t('case.preparingFiles')}
										</p>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</Header>
			<div className="p-4 pb-1 bg-white mx-18">
				<Box title="" form>
					{loadingUploadFiles || (loadingData && typeof data === 'undefined') ? (
						<div className="relative h-40">
							<Loading />
						</div>
					) : (
						<div className="text-blue">
							<div className="flex flex-row justify-between mb-2">
								<div className="flex flex-row space-x-2">
									<h2 className="text-xl font-semibold text-blue">{t('common.pictures')}</h2>
									{zippedCaseImagesFlag && (
										<>
											<TextButton
												icon={faFileArchive}
												text="case.getAllImages"
												onClick={() => getZippedCaseImagesRest(null)}
												loading={gettingZip.loading && gettingZip.type === 'all'}
												disabled={gettingZip.loading}
											/>
											<TextButton
												icon={faFileDownload}
												text="common.downloadSelected"
												onClick={() => {
													getZippedCaseImagesRest(selectedFileIds);
												}}
												loading={gettingZip.loading && gettingZip.type === 'selected'}
												disabled={gettingZip.loading || selectedFileIds.length === 0}
											/>
											<TextButton
												icon={faCheckCircle}
												text="common.selectAll"
												onClick={() => {
													setSelectedFileIds(allImages.map(x => x.fileId));
												}}
											/>
											<TextButton
												icon={faTimesCircle}
												text="common.deselectAll"
												onClick={() => {
													setSelectedFileIds([]);
												}}
											/>
										</>
									)}
								</div>
								{loadingData && (
									<p className="pr-2">
										<FontAwesomeIcon icon={faSpinner} className="mr-1 text-blue animate-spin" size="1x" />
										{t('case.gettingFiles')}
									</p>
								)}
							</div>
							{imageCategorizationUI ? (
								<div>
									{drivingslipImages?.length ? (
										Object.keys(groupedFiles).map(drivingslipId => (
											<div key={drivingslipId} className="mb-4 border-1 border-blue">
												<div className="w-full p-2 text-white bg-blue">
													<p className="font-bold">{findDrivingSlipById(drivingslipId)}</p>
												</div>
												{Object.keys(groupedFiles[drivingslipId])
													.reverse()
													.map(questionnaireSection => (
														<div key={questionnaireSection} className="p-2">
															{questionnaireSection && (
																<div className="mb-2 border-b-1 border-blue">
																	<p className="font-bold">{questionnaireSection}</p>
																</div>
															)}
															<div className="flex flex-wrap">
																{groupedFiles[drivingslipId][questionnaireSection].map(file => (
																	<FileImage
																		key={file.fileId}
																		image={{ fileName: file.name, src: file.thumbnail, comments: file.comment }}
																		canDelete={!caseViewExternal}
																		canSelect={zippedCaseImagesFlag}
																		loadingDeleteFile={loadingDeleteFile}
																		selected={selectedFileIds.includes(file.fileId)}
																		onClick={() => {
																			const drivingslipImageIndex = allImages.findIndex(f => f.fileId === file.fileId);
																			setGalleryIndex(drivingslipImageIndex);
																			setShowGallery(true);
																		}}
																		onDelete={() => {
																			deleteFile(file.name);
																		}}
																		onSelect={(checked: boolean) => onSelectFile(checked, file.fileId)}
																	/>
																))}
															</div>
														</div>
													))}
											</div>
										))
									) : (
										<div>{t('file.noPictures')}</div>
									)}
								</div>
							) : (
								<div className="flex flex-row flex-wrap w-full">
									{allImages?.length ? (
										allImages.map((image, index) => {
											return (
												<FileImage
													key={index}
													image={{ fileName: image.name, src: image.thumbnail, comments: image.comments }}
													canDelete={!caseViewExternal}
													canSelect={zippedCaseImagesFlag}
													loadingDeleteFile={loadingDeleteFile}
													selected={selectedFileIds.includes(image.fileId)}
													onClick={() => {
														setGalleryIndex(index);
														setShowGallery(true);
													}}
													onDelete={() => {
														deleteFile(image.name);
													}}
													onSelect={(checked: boolean) => onSelectFile(checked, image.fileId)}
												/>
											);
										})
									) : (
										<div>{t('file.noPictures')}</div>
									)}
								</div>
							)}
							<div className="flex flex-row flex-wrap w-full">
								{caseImages?.length ? (
									caseImages.map((image, index) => {
										return (
											<FileImage
												key={index}
												image={{ fileName: image.name, src: image.thumbnail, comments: image.comments }}
												canDelete={!caseViewExternal}
												canSelect={zippedCaseImagesFlag}
												loadingDeleteFile={loadingDeleteFile}
												selected={selectedFileIds.includes(image.fileId)}
												onClick={() => {
													const imageIndex = allImages.findIndex(f => f.fileId === image.fileId);
													setGalleryIndex(imageIndex);
													setShowGallery(true);
												}}
												onDelete={() => {
													deleteFile(image.name);
												}}
												onSelect={(checked: boolean) => onSelectFile(checked, image.fileId)}
											/>
										);
									})
								) : (
									<div>{t('file.noPictures')}</div>
								)}
							</div>
							<h2 className="mb-2 text-xl font-semibold text-blue">{t('common.videos')}</h2>
							<div className="flex flex-row flex-wrap w-full">
								{videos?.length ? (
									videos.map((video, index) => {
										return (
											<div key={index}>
												<div className="w-40 mr-4">
													<video width="320" height="240" controls>
														<source src={video.url} type="video/mp4" />
														Your browser does not support the video tag.
													</video>
													<p className="... truncate text-sm">{video.comments}</p>
												</div>
											</div>
										);
									})
								) : (
									<div>{t('file.noVideos')}</div>
								)}
							</div>
							<h2 className="mb-2 text-xl font-semibold text-blue">{t('common.offer')}</h2>
							<div className="flex flex-row flex-wrap w-full">
								{offerFiles.length > 0 ? (
									offerFiles.map((f, index) => (
										<FileItem
											key={index + f.name}
											file={f}
											setFileData={() =>
												setFileData({
													fileName: f.name,
													folder: data?.case.erpNo,
												})
											}
											setFileToDelete={() => setFileToDelete(f.name)}
											loading={fileLoading && f.name === fileData.fileName}
											canDelete={!caseViewExternal}
											isExternalUser={isExternalUser}
										/>
									))
								) : (
									<div>{t('offer.none')}</div>
								)}
							</div>

							<h2 className="mb-2 text-xl font-semibold text-blue">{t('case.screenings')}</h2>
							<div className="flex flex-row flex-wrap w-full">
								{screeningFiles.length > 0 ? (
									screeningFiles.map((f, index) => (
										<FileItem
											key={index + f.name}
											file={f}
											setFileData={() =>
												setFileData({
													fileName: f.name,
													folder: data?.case.erpNo,
												})
											}
											setFileToDelete={() => setFileToDelete(f.name)}
											loading={fileLoading && f.name === fileData.fileName}
											isExternalUser={isExternalUser}
											canDelete={!caseViewExternal}
										/>
									))
								) : (
									<div>{t('case.noScreenings')}</div>
								)}
							</div>

							<h2 className="mb-2 text-xl font-semibold text-blue">{t('case.reports')}</h2>
							<div className="flex flex-row flex-wrap w-full">
								{reports?.length ? (
									reports.map((f, index) => (
										<FileItem
											key={index + f.name}
											file={f}
											setFileData={() =>
												setFileData({
													fileName: f.name,
													folder: data?.case.erpNo,
												})
											}
											setFileToDelete={() => setFileToDelete(f.name)}
											loading={fileLoading && f.name === fileData.fileName}
											isExternalUser={isExternalUser}
											canDelete={!caseViewExternal}
										/>
									))
								) : (
									<div>{t('file.noDocuments')}</div>
								)}
							</div>

							{!isExternalUser && (
								<>
									<h2 className="mb-2 text-xl font-semibold text-blue">{t('case.otherFiles')}</h2>
									<div className="flex flex-row flex-wrap w-full">
										{otherFiles?.length ? (
											otherFiles.map((f, index) => (
												<FileItem
													key={index + f.name}
													file={f}
													setFileData={() =>
														setFileData({
															fileName: f.name,
															folder: data?.case.erpNo,
														})
													}
													setFileToDelete={() => setFileToDelete(f.name)}
													loading={fileLoading && f.name === fileData.fileName}
													isExternalUser={isExternalUser}
													canDelete={!caseViewExternal}
												/>
											))
										) : (
											<div>{t('file.noDocuments')}</div>
										)}
									</div>
								</>
							)}

							<h2 className="mb-2 text-xl font-semibold text-blue">{t('common.emails')}</h2>
							<Table
								data={emails ?? []}
								columns={[
									{
										label: 'common.name',
										selectFn: e => <p>{e.name}</p>,
									},
									{
										label: 'mail.sentTo',
										selectFn: e => <p>{e.metadata.find(m => m.key === 'Mailtil')?.value}</p>,
									},
									{
										label: 'mail.CC',
										selectFn: e => <p>{e.metadata.find(m => m.key === 'MailCC')?.value}</p>,
									},
									{
										label: 'mail.dateSent',
										selectFn: e => <p>{dateToDateTimeString(e.metadata.find(m => m.key === 'Created')?.value ?? '')}</p>,
									},
									{
										label: 'common.download',
										selectFn: e =>
											<TextButton
												icon={e.extension === 'msg' ? faExternalLink : faEnvelope}
												text={e.extension === 'msg' ? 'mail.inSharePoint' : 'case.mailEml'}
												className="pr-4"
												onClick={() => {
													if (!isExternalUser) {
														window.open(e.extension === 'msg' ? createSPShareLink(data?.case.erpNo ?? '', e.name) : e.url, '_blank');
													} else {
														setFileData({
															fileName: e.name,
															folder: data?.case.erpNo,
														});
													}
												}}
											/>,
									},
									{
										label: t('common.remove'),
										classNameTh: 'text-right',
										selectFn: e => (
											<div>
												<div className="flex content-start justify-end text-right text-red">
													<FontAwesomeIcon
														icon={faTrashAlt}
														size="lg"
														onClick={() => {
															if (!loadingDeleteFile) {
																deleteFile(e.name);
															}
														}}
														className="cursor-pointer"
													/>
												</div>
											</div>
										),
										hideColumn: caseViewExternal,
									},
								]}
								keySelector={e => e.name}
								noDataFoundText="file.noMails"
							/>
						</div>
					)}
				</Box>
			</div>

			{showGallery && (
				<Modal
					title={''}
					size={ModalSize.XLARGE}
					close={() => {
						setShowGallery(false);
					}}
					visible={showGallery}
					body={
						<div style={{ height: '80vh' }}>
							<p className="text-center">{gallery[galleryIndex].comment}</p>

							<div className="m-auto h-3/4">
								<ImageGallery
									items={gallery}
									ref={galleryRef}
									showPlayButton={false}
									showFullscreenButton={false}
									startIndex={galleryIndex}
									onSlide={(value: number) => setGalleryIndex(value)}
								/>
							</div>
						</div>
					}
					footer={
						<div>
							{hrefImageDownload ? (
								<a href={`https://ssgcloud.sharepoint.com/sites/SSGESDH/_layouts/15/download.aspx?sourceurl=${gallery[galleryIndex].url}`} download>
									<Button primary text="common.download" icon={faFileDownload} />
								</a>
							) : (
								<Button
									primary
									text="common.download"
									icon={faFileDownload}
									onClick={() => {
										setFileData({
											fileName: gallery[galleryIndex].description ?? '',
											folder: data?.case.erpNo,
										});
									}}
									loading={fileLoading}
								/>
							)}
						</div>
					}
				/>
			)}
			{typeof fileToDelete !== 'undefined' && (
				<Modal
					title={`${t('common.delete')} ${fileToDelete}?`}
					size={ModalSize.MEDIUM}
					close={() => {
						setFileToDelete(undefined);
					}}
					visible={typeof fileToDelete !== 'undefined'}
					body={<></>}
					footer={
						<div className="flex space-x-2">
							<Button danger text="case.confirmDeleteFile" disabled={loadingDeleteFile} onClick={() => deleteFile(fileToDelete)} loading={loadingDeleteFile} />

							<Button secondary text="common.cancel" onClick={() => setFileToDelete(undefined)} />
						</div>
					}
				/>
			)}
		</>
	);
};

export default FileOverview;
